<template>
  <div class="hk-coffee-neon-holder">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 111.6 111.6">
      <path id="shape0" transform="matrix(0.999999999488383 0 0 0.999999999488383 9.92593017643756 36.2214281201148)" fill="none" stroke="#ffffff" stroke-width="2.16" stroke-linecap="round" stroke-linejoin="round" d="M36.3527 46.405C27.5288 46.8866 17.7621 43.7959 12.2101 38.8253C5.86219 33.0527 0.930171 23.9779 0.0549067 3.75981C-0.37258 -0.26613 1.74913 -0.513119 4.57332 0.577834C28.5689 7.15603 47.7988 10.0522 74.2587 0.705104C77.0352 -0.716676 78.9081 -0.0367446 78.6498 3.18706C76.8443 17.0549 76.3019 27.5949 68.2766 37.0433C63.2048 43.2635 54.5489 46.043 41.8427 46.495"/><path id="shape1" transform="matrix(0.999999999488383 0 0 0.999999999488383 11.943628262553 22.6231530091892)" fill="none" stroke="#ffffff" stroke-width="2.16" stroke-linecap="round" stroke-linejoin="round" d="M0 8.56825C14.6099 -2.57757 57.4516 -3.0696 73.98 8.38825"/><path id="shape2" transform="matrix(0.999999999488383 0 0 0.999999999488383 5.02352870464692 70.6113973189731)" fill="none" stroke="#ffffff" stroke-width="2.16" stroke-linecap="round" stroke-linejoin="round" d="M7.28009 0C-1.08056 1.49996 -4.3803 8.92525 9.1701 16.02C29.1552 26.0254 60.6993 26.1296 81.3501 15.84C90.0229 11.5918 95.0737 4.48479 80.5401 0.27"/><path id="shape3" transform="matrix(0.999999999488383 0 0 0.999999999488383 90.35767272152 40.7349771761231)" fill="none" stroke="#ffffff" stroke-width="2.16" stroke-linecap="round" stroke-linejoin="round" d="M0.954597 18.9109C5.64087 18.2576 15.8777 7.52297 5.98211 5.16474C2.94028 4.36709 3.39938 -0.0583034 6.61851 0.0735778C19.8154 -1.3866 21.012 19.3124 0 24.3202"/>
    </svg>
  </div>
</template>

<style scoped lang="scss">
  @import '@/styles/variables';
  @import '@/styles/animations';

  .hk-coffee-neon-holder {
    position: relative;
  }
  #hk-marshneon-absolute {
    position: absolute;
    left: 0;
    animation: neon_marshs 1.7s linear infinite;
    path {
      stroke: #fefefe;
    }
  }

  svg{
    width: 200px;
    height: 200px;
    > * {
      stroke: lighten($accent_main, 10%);
    }
    animation: neon 6s linear infinite;
  }
</style>
